import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import Layout from '../../components/layout';
import { getObject } from '../../services/storage'
import { Container, Row, Col } from 'reactstrap'
import { navigate } from 'gatsby';
import { isLoggedIn, isBrowser } from '../../services/auth'
import { ResBar } from '../../components/sidebar';
import { Banner } from '../../components/banner'
import { handleCmsFile } from '../../services/api'

export default class certification extends Component {
    constructor(props) {
        super(props);

        // let objContent = "resource.json"
        // let envPrefix = "staging"
        // const objUri = envPrefix + '-' + objContent;

        this.state = {
            objItems: false,
            objStyle: false,
            reqbody: '',
            resheader:'',
            resbody:'',
            rescode:''

        }; 
       
        // if(isBrowser()) this.renderObject(objUri)
    }

    

    renderObject = async (objVar) => {

        this.setState({
            objItems : await getObject(objVar),
        })

    }

    renderStyle = async (objVar) => {
        this.setState({
            objStyle : await getObject(objVar),
        })
    }

    async componentDidMount() {
        if(!isLoggedIn()){
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
        }

        let result
        var data = {
            stage_file: 'staging-resource.json',
            status: "current"
        }

        
        let files = await handleCmsFile(data)
        // console.log(files)
        files.data.forEach(function(item,key){
            if(item.status === 'current'){
                result = item.data      
            }
        })

        console.log(result)
        this.setState({
            objItems: result
        })

        

        const script = document.createElement("script");
  script.src = "/main.js";
  script.async = true;
  script.onload = () => this.scriptLoaded();

  document.body.appendChild(script);

  

    }

    scriptLoaded(){

    }


    
    runtc01(){
        window.tc01();
    }
    runtc02(){
        window.tc02();
    }
    runtc03(){
        window.tc03();
    }
    runtc04(){
        window.tc04();
    }
    runtc05(){
        window.tc05();
    }
    runtc06(){
        window.tc06();
    }
    runtc07(){
        window.tc07();
    }
    runtc08(){
        window.tc08();
    }
    runtc09(){
        window.tc09();
    }
    runtc10(){
        window.tc10();
    }
    runtc11(){
        window.tc11();
    }
    runtc12(){
        window.tc12();
    }
    runtc13(){
        window.tc13();
    }
    
    runcg01(){
        window.CG01();
    }
    runcg02(){
        window.CG02();
    }
    runcg03(){
        window.CG03();
    }
    runcg04(){
        window.CG04();
    }
    runcg05(){
        window.CG05();

    }

    render() {
        // if(this.state.objItems.post_content !== undefined){
        //     var content = this.state.objItems.post_content
        // }else{
            var content = this.state.objItems
        // }
        if(isLoggedIn()){
            return (
                <Layout>
                    <Banner title={`Certification`} />
                    <Container fluid>
                        <Row>
                            <Col lg={10} md={10} sm={12}>
                            <div>

                                <br></br><br></br>
        <form name="myForm">
          <h2>API Tester</h2>
          <table style={{width: '100%'}} border={1}>
            <tbody><tr>
                <th bgcolor="#fbff35">Test cases</th>
                <th bgcolor="#fbff35">Generate QR</th> 
              </tr>
              <tr>
                <th>CD01:Mandatory fields </th>
                <td> <input type="button" id="TC01" onClick={this.runtc01} defaultValue="Generate" /></td>
              </tr>
              <tr>
              </tr><tr>
                <th>CD02:No corresponding Order Request </th>
                <td> <input type="button" id="TC02" onClick={this.runtc02}  defaultValue="Generate" /></td>
              </tr>
              <tr>
                <th>CD03:Undefined response code for PayAdvice request</th>
                <td>  <input type="button" id="TC03" onClick={this.runtc03} defaultValue="Generate" /> 
                </td></tr>
              <tr>
                <th>CD04:Late Order request</th>
                <td>  <input type="button" id="TC04" onClick={this.runtc04} defaultValue="Generate" /> 
                </td></tr>
              <tr>
                <th>CD05:Timeout for PayAdvice request </th>
                <td>  <input type="button" id="TC05" onClick={this.runtc05} defaultValue="Generate" /> 
                </td></tr>
              <tr>
                <th>CD06:Timeout for PayQuery request </th>
                <td>  <input type="button" id="TC06" onClick={this.runtc06} defaultValue="Generate" /> 
                </td></tr>
              <tr>
                <th>CD07:Timeout after 3 PayQuery requests </th>
                <td>  <input type="button" id="TC07" onClick={this.runtc07} defaultValue="Generate" /> 
                </td></tr>
              <tr>
                <th>CD08:Reversal </th>
                <td>  <input type="button" id="TC08" onClick={this.runtc08} defaultValue="Generate" /> 
                </td></tr>
              <tr>
                <th>CD09:Timeout for PayAdvice request</th>
                <td>  <input type="button" id="TC09" onClick={this.runtc09} defaultValue="Generate" /> 
                </td></tr>
              <tr>
                <th>CD10:Undefined response code for PayAdvice request </th>
                <td>  <input type="button" id="TC10" onClick={this.runtc10} defaultValue="Generate" /> 
                </td></tr>
              <tr>
                <th>CD11:’09’ response code for PayQuery       </th>
                <td>  <input type="button" id="TC11" onClick={this.runtc11} defaultValue="Generate" /> 
                </td></tr>
              <tr>
                <th>CD12:’09’ response codes for 3 PayQuery </th>
                <td>  <input type="button" id="TC12" onClick={this.runtc12} defaultValue="Generate" /> 
                </td></tr>   
              <tr>
                <th>CD13:Invalid Signature </th>
                <td>  <input type="button" id="TC13" onClick={this.runtc13} defaultValue="Generate" /> 
                </td></tr>   
              <tr>
                <th>CG01:Sample 1 </th>
                <td>  <input type="button" id="CG01" onClick={this.runcg01} defaultValue="Generate" /> 
                </td></tr>   
              <tr>
                <th>CG02:Sample 2</th>
                <td>  <input type="button" id="CG02" onClick={this.runcg02} defaultValue="Generate" /> 
                </td></tr>     
              <tr>
                <th>CG03:Sample 3</th>
                <td>  <input type="button" id="CG03" onClick={this.runcg03} defaultValue="Generate" /> 
                </td></tr>   
              <tr>
                <th>CG04:Sample 4 </th>
                <td>  <input type="button" id="CG04" onClick={this.runcg04} defaultValue="Generate" /> 
                </td></tr>   
              <tr>
                <th>CG05:Sample 5 </th>
                <td>  <input type="button" id="CG05" onClick={this.runcg05} defaultValue="Generate" /> 
                </td></tr>   
              {/*
   <tr>
    <th>Test Case 7 (static)</th>
    <td>
    <input type="button" id="TC07QR"  value="send QRquery"/>
    </td>
  </tr>
   <tr>
    <th>Test Case 8 (static)</th>
    <td>
    <input type="button" id="TC08QR"  value="send QRquery"/>
    </td>
  </tr>
   <tr>
    <th>Test Case 9 (static)</th>
    <td>
    <input type="button" id="TC09QR"  value="send QRquery"/>
    </td>
  </tr>
   <tr>
    <th>Test Case 10 (static)</th>
    <td>
    <input type="button" id="TC10QR"  value="send QRquery"/>
    </td>
  </tr>
*/}
            </tbody></table>
          <br />
        </form>
        <br /> 
        <h2> Response Message </h2>
        <table border={1} style={{width: '100%'}}>
          <tbody><tr><th bgcolor="#fbff35">Parameters</th><th bgcolor="#fbff35">Value</th> </tr>
            {/*<tr><td>destination :</td><td id="destination" /></tr>
            {/* <tr><td>keyId :</td> <td id="KeyId"></td></tr> *}
            <tr><td>Signature :</td><td id="signature" /></tr>
            {/*	<tr><td>Secret :</td><td id="secret"></td></tr> *}
            <tr><td>Request Body :</td><td> <div id="RequestBody"></div> </td></tr>
            <tr><td>Response Headers :</td><td><div id="ResponseHeaders"></div></td></tr>
            <tr><td>Response Body :</td><td><div id="Response"></div> </td></tr>
<tr><td>Response Code :</td><td> <div id="ResponseCode"></div></td></tr>*/}
            <tr><td>QR Image :</td> <td> 
                <table style={{width: '100%'}}> <tbody><tr> <th id="txdId" style={{whiteSpace:'pre',fontFamily:'Courier'}} /> </tr> <tr> <th> <div id="qrcode" className="qr" /></th> </tr> 
                  </tbody></table></td></tr>
          </tbody></table>
      </div>

     

                            </Col>
                        </Row>
    
                    </Container>
                </Layout>
            )
        } else {
            return ''
        }
        
    }
}
